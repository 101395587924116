
/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { SoundTwoTone } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'notPC',
    components: {
        SoundTwoTone: SoundTwoTone
    }
})
